import { InputHTMLAttributes, useEffect, useRef } from 'react'
import clsx from 'clsx'

import { Label } from '../Label'

const inputStyle = `
  !ring-0
  disabled:bg-foregroundNeutralDisabled
  hover:text-backgroundBrandDefaultHover
  text-primary-main
  focus:outline-borderBrandDefaultFocus
  focus:outline-2
  focus:outline-offset-1
  border-solid
  border-borderNeutralDefault
  disabled:border
  rounded-sm
  cursor-pointer
  box-content
`

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  testId?: string
  className?: string
  /** When indeterminate is true, the checkbox has a horizontal line in the box until the component is interacted with */
  indeterminate?: boolean
  checkboxSize?: 'xSmall' | 'small' | 'medium' | 'large'
  labelText?: string
  helperText?: string
  labelPosition?: 'left' | 'right'
}

export const Checkbox = ({
  className,
  indeterminate,
  checkboxSize = 'medium',
  testId,
  labelText,
  helperText,
  labelPosition = 'left',
  ...inputProps
}: CheckboxProps) => {
  const cRef = useRef<HTMLInputElement>(null)

  const sizeClasses = () => {
    switch (checkboxSize) {
      case 'xSmall':
      case 'small':
        return 'w-4 h-4'
      case 'medium':
        return 'w-5 h-5'
      case 'large':
        return 'w-6 h-6'
    }
  }

  const getLabelSize = (checkboxSize: 'xSmall' | 'small' | 'medium' | 'large') => {
    switch (checkboxSize) {
      case 'small':
        return 'medium'
      case 'medium':
        return 'medium'
      case 'large':
        return 'large'
      default:
        return checkboxSize
    }
  }

  useEffect(() => {
    if (cRef?.current && indeterminate != undefined) {
      cRef.current.indeterminate = indeterminate
    }
  }, [cRef, indeterminate])

  return (
    <div
      className={clsx(
        helperText ? 'items-start' : 'items-center',
        'inline-flex gap-x-3',
        labelPosition === 'right' && 'flex-row-reverse',
      )}
    >
      <input
        ref={cRef}
        role="checkbox"
        type="checkbox"
        className={clsx(inputStyle, className, sizeClasses())}
        data-testid={testId}
        aria-checked={inputProps.checked}
        {...inputProps}
      />
      <Label
        className="cursor-pointer"
        appearance="secondary"
        size={getLabelSize(checkboxSize)}
        helperText={helperText}
        labelText={labelText}
        labelStrong={false}
      />
    </div>
  )
}

Checkbox.displayName = 'Checkbox'
