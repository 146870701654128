export const getArrowClasses = (position?: 'top' | 'bottom' | 'left' | 'right') => {
  switch (position) {
    case 'top':
      return 'h-3 w-3 rotate-45 inset-y-full -ml-1.5 -mt-1.5 border-t-0 border-l-0'
    case 'bottom':
      return 'h-3 w-3 rotate-45 inset-y-0 -ml-1.5 -mt-1.5 border-b-0 border-r-0'
    case 'right':
      return 'h-3 w-3 rotate-45 -ml-1.5 inset-x-0 border-t-0 border-r-0'
    case 'left':
      return 'h-3 w-3 rotate-45 -ml-1.5 inset-x-full border-b-0 border-l-0'
    default:
      'h-3 w-3 rotate-45 inset-y-0 -ml-1.5 -mt-1.5 border-b-0 border-r-0'
  }
}
