import { useMutation, useQuery } from '@tanstack/react-query'

import { queries } from '@shared/data/practice/queries'
import {
  Accessor,
  AddGuidePractitioner,
  AddPractitioner,
  AddTreatmentPlan,
  AttachmentSuccess,
  CheckoutSessionArgs,
  DivideBuyInstalment,
  FetchError,
  GetAttachmentsArgs,
  GetPatientOverview,
  GetPatientOverviewArgs,
  GetPracticeInstalments,
  GetPracticeInstalmentsArgs,
  GetPracticeMembers,
  GetPractitioners,
  GetTreatmentDefinition,
  GetTreatmentDefinitions,
  GuidePractitioner,
  GuidePractitionerPatch,
  ITreatmentGuide,
  MultiAddAttachments,
  MultiAttachmentUrl,
  MultiPresignedUrl,
  PatchPracticeArgs,
  PatchPracticeMembersArgs,
  PatchPractitionersArgs,
  PatchTreatmentGuideArgs,
  Patient,
  PatientInfo,
  PatientSignUp,
  PostAttachments,
  PostAttachmentsArgs,
  PostDividebuyRedirectArgs,
  PostPracticeArgs,
  PostPracticeMembers,
  PostPracticeMembersArgs,
  PostPractitioners,
  PostPractitionersArgs,
  PostTreatmentGuides,
  PostTreatmentGuidesArgs,
  Practice,
  PracticeDetails,
  PracticeDetailsPatch,
  PracticePatients,
  PracticePratitioners,
  PracticePublic,
  Practitioner,
  PractitionerDetailsPatch,
  PractitionerUserDetails,
  PresignedUrl,
  SendPlanArgs,
  StripeSession,
  TreatmentDefinition,
  TreatmentGuide,
  TreatmentGuideId,
  TreatmentPlanSuccess,
  TreatmentPlanUrl,
  UpdateIsArchivedArgs,
  UpdateTreatmentGuideArgs,
  User,
} from '@shared/data/types'

import { mutations } from './mutations'

// NOTE: custom hooks make testing easier
// https://tanstack.com/query/v4/docs/react/guides/testing

// TODO: Remove QueryArgsAccessor2, accessor should not be passed in as a query key we can pass it into the queryFn function as seen in QueryArgsAccessor2

export const usePracticePatientListQuery = () =>
  useQuery<unknown, FetchError, PracticePatients>(queries.PRACTICE_PATIENT_LIST)

export const usePracticeAvailableInstalmentsUnprotected = ({
  orderTotal,
  practiceId,
}: {
  orderTotal: string
  practiceId: string
}) =>
  useQuery<DivideBuyInstalment[], FetchError, DivideBuyInstalment[], string[]>({
    queryKey: [queries.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED.queryKey[0], orderTotal, practiceId],
    queryFn: queries.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED.queryFn,
    enabled: false,
  })

export const usePracticeDetailsUnprotectedQuery = (practiceId: string) =>
  useQuery<PracticeDetails, FetchError, PracticeDetails, string[]>({
    queryKey: [queries.PRACTICE_DETAILS_UNPROTECTED.queryKey[0], practiceId as string],
    queryFn: queries.PRACTICE_DETAILS_UNPROTECTED.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePracticePractitionersListQuery = (options?: {
  enabled?: boolean
  onSuccess: (data: PracticePratitioners) => void
}) =>
  useQuery<PracticePratitioners, FetchError, PracticePratitioners>({
    ...queries.PRACTICE_PRACTITIONER_LIST,
    ...(options && options),
    enabled: options?.enabled ?? true,
  })

export const usePractitionerUserDetailsQuery = (enabled = true) =>
  useQuery<PractitionerUserDetails, FetchError>({
    ...queries.PRACTITIONER_USER_DETAILS,
    enabled,
  })

export const usePracticeDetailsQuery = (practiceId: string) =>
  useQuery<PracticeDetails, FetchError, PracticeDetails, string[]>({
    queryKey: [queries.PRACTICE_DETAILS.queryKey[0], practiceId as string],
    queryFn: queries.PRACTICE_DETAILS.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePracticeDetailsMutation = (options?: { onSuccess: () => void }) =>
  useMutation<PracticeDetails, FetchError, PracticeDetailsPatch>({
    mutationFn: mutations.PRACTICE_DETAILS.mutationFn,
    ...(options && options),
  })

export const useAddPractitionerMutation = (options?: {
  onSuccess: (data: Practitioner) => void
  onError?: (err: FetchError) => void
}) =>
  useMutation<Practitioner, FetchError, AddPractitioner>({
    mutationFn: mutations.PRACTITIONER_ADD.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateDetailsMutation = (options?: { onSuccess: () => void }) =>
  useMutation<Practitioner, FetchError, PractitionerDetailsPatch>({
    mutationFn: mutations.PRACTITIONER_UPDATE.mutationFn,
    ...(options && options),
  })

export const useAddGuidePractitionerMutation = (options?: { onSuccess: () => void }) =>
  useMutation<AddGuidePractitioner, FetchError, AddGuidePractitioner>({
    mutationFn: mutations.GUIDE_PRACTITIONER_ADD.mutationFn,
    ...(options && options),
  })

export const useUpdateGuidePractitionerMutation = (options?: { onSuccess: () => void }) =>
  useMutation<GuidePractitioner, FetchError, GuidePractitionerPatch>({
    mutationFn: mutations.GUIDE_PRACTITIONER_UPDATE.mutationFn,
    ...(options && options),
  })

export const useTreatmentDefinitionQuery = (treatmentName: string | undefined, options?: { enabled: boolean }) =>
  useQuery<TreatmentDefinition, FetchError, TreatmentDefinition, string[]>({
    queryKey: [queries.TREATMENT_DEFINITION.queryKey[0], treatmentName as string],
    queryFn: queries.TREATMENT_DEFINITION.queryFn,
    ...(options && options),
  })

export const usePractitionerTreatmentGuideMutation = (options?: { onSuccess: (data: TreatmentGuideId) => void }) =>
  useMutation<TreatmentGuideId, FetchError, TreatmentGuide>({
    mutationFn: mutations.PRACTITIONER_CREATE_TREATMENT_GUIDE.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateTreatmentGuideMutation = (options?: {
  onSuccess: (data: TreatmentGuideId) => void
}) =>
  useMutation<TreatmentGuideId, FetchError, UpdateTreatmentGuideArgs>({
    mutationFn: mutations.PRACTITIONER_UPDATE_TREATMENT_GUIDE.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateIsArchivedMutation = (options?: { onSuccess: (data: TreatmentGuideId) => void }) =>
  useMutation<TreatmentGuideId, FetchError, UpdateIsArchivedArgs>({
    mutationFn: mutations.PRACTITIONER_UPDATE_IS_ARCHIVED.mutationFn,
    ...(options && options),
  })

export const usePractitionerPatientInfoQuery = (patientId: string | undefined) =>
  useQuery<PatientInfo, FetchError, PatientInfo, string[]>({
    queryKey: [queries.PRACTITIONER_PATIENT_INFO.queryKey[0], patientId as string],
    queryFn: queries.PRACTITIONER_PATIENT_INFO.queryFn,
  })

export const usePractitionerPatientSignUpMutation = (options?: {
  onSuccess: (data: Patient) => void
  onError?: (err: FetchError) => void
}) =>
  useMutation<Patient, FetchError, PatientSignUp>({
    mutationFn: mutations.PRACTITIONER_PATIENT_SIGNUP.mutationFn,
    ...(options && options),
  })

export const usePractitionerGetPreAssignedUrlMutation = () =>
  useMutation<PresignedUrl, FetchError, TreatmentPlanUrl>({
    mutationFn: mutations.PRACTITIONER_TREATMENT_PLAN_URL.mutationFn,
  })

export const usePractitionerAddTreatmentPlan = () =>
  useMutation<TreatmentPlanSuccess, FetchError, AddTreatmentPlan>({
    mutationFn: mutations.PRACTITIONER_ADD_TREATMENT_PLAN.mutationFn,
  })

export const usePractitionerRemoveTreatmentPlan = () =>
  useMutation<void, FetchError, { filename: string }>({
    mutationFn: mutations.PRACTITIONER_REMOVE_TREATMENT_PLAN.mutationFn,
  })

export const usePractitionerResendInvite = (options?: { onSuccess: () => void }) =>
  useMutation<void, FetchError, TreatmentGuideId>({
    mutationFn: mutations.PRACTITIONER_RESEND_INVITE.mutationFn,
    ...(options && options),
  })

export const usePracticeGuidePractitionersQuery = (
  treatmentGuideId: string | undefined,
  options?: {
    enabled: boolean
    onSuccess?: (data: GuidePractitioner[]) => void
  },
) =>
  useQuery<GuidePractitioner[], FetchError, GuidePractitioner[], string[]>({
    queryKey: [queries.GUIDE_PRACTITIONER.queryKey[0], treatmentGuideId as string],
    queryFn: queries.GUIDE_PRACTITIONER.queryFn,
    ...(options && options),
  })

export const usePracticeAvailableInstalments = (
  { amount, treatmentGuideId }: { treatmentGuideId: string; amount: string },
  options?: { enabled: boolean },
) =>
  useQuery<DivideBuyInstalment[], FetchError, DivideBuyInstalment[], string[]>({
    queryKey: [queries.PRACTICE_AVAILABLE_INSTALMENTS.queryKey[0], amount, treatmentGuideId],
    queryFn: queries.PRACTICE_AVAILABLE_INSTALMENTS.queryFn,
    ...(options && options),
  })

export const usePractitionerGetMultiPreAssignedUrlMutation = () =>
  useMutation<MultiPresignedUrl, FetchError, MultiAttachmentUrl>({
    mutationFn: mutations.PRACTITIONER_ATTACHMENTS_URL.mutationFn,
  })

export const usePractitionerAddAttachment = () =>
  useMutation<AttachmentSuccess, FetchError, MultiAddAttachments>({
    mutationFn: mutations.PRACTITIONER_ADD_ATTACHMENTS.mutationFn,
  })

export const usePractitionerRemoveAttachment = () =>
  useMutation<void, FetchError, MultiAddAttachments>({
    mutationFn: mutations.PRACTITIONER_REMOVE_ATTACHMENTS.mutationFn,
  })

export const usePractitionerAccountPayments = () =>
  useMutation<StripeSession, FetchError, CheckoutSessionArgs>({
    mutationFn: mutations.PRACTITIONER_ACCOUNT_PAYMENTS.mutationFn,
  })

export const usePractitionerSendPlan = () =>
  useMutation<void, FetchError, SendPlanArgs>({
    mutationFn: mutations.PRACTITIONER_SEND_PLAN.mutationFn,
  })

// request hooks for new backend API
/**
 * Returns the user details and the practices they belong to
 *  It also creates a user in the DB if one doesn't exist already
 */
export const useGetAuthUser = (accessor: Accessor) =>
  useQuery<User, FetchError, { data: User }, string[]>({
    queryKey: [queries.GET_AUTH_USER.queryKey[0]],
    queryFn: (context) => queries.GET_AUTH_USER.queryFn(context, accessor),
  })

export const useGetPracticeMembers = (practiceId: string) =>
  useQuery<GetPracticeMembers, FetchError, GetPracticeMembers, string[]>({
    queryKey: [queries.GET_PRACTICE_MEMBERS.queryKey[0], practiceId],
    queryFn: queries.GET_PRACTICE_MEMBERS.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePostPracticeMembers = () =>
  useMutation<PostPracticeMembers, FetchError, PostPracticeMembersArgs>({
    mutationFn: mutations.POST_PRACTICE_MEMBERS.mutationFn,
  })

export const usePatchPracticeMembers = () =>
  useMutation<void, FetchError, PatchPracticeMembersArgs>({
    mutationFn: mutations.PATCH_PRACTICE_MEMBERS.mutationFn,
  })

export const usePostPractice = () =>
  useMutation<void, FetchError, PostPracticeArgs>({
    mutationFn: mutations.POST_PRACTICE.mutationFn,
  })

export const usePatchPractice = () =>
  useMutation<void, FetchError, PatchPracticeArgs>({
    mutationFn: mutations.PATCH_PRACTICE.mutationFn,
  })

export const useGetPractice = (practiceId: string, accessor: Accessor) =>
  useQuery<Practice, FetchError, { data: Practice }, string[]>({
    queryKey: [queries.GET_PRACTICE.queryKey[0], practiceId, accessor],
    queryFn: (context) => queries.GET_PRACTICE.queryFn(context, practiceId, accessor),
    enabled: Boolean(practiceId),
  })

export const useGetPractitioners = (practiceId: string) =>
  useQuery<GetPractitioners, FetchError, GetPractitioners, string[]>({
    queryKey: [queries.GET_PRACTITIONERS.queryKey[0], practiceId as string],
    queryFn: queries.GET_PRACTITIONERS.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePostPractitioners = () =>
  useMutation<PostPractitioners, FetchError, PostPractitionersArgs>({
    mutationFn: mutations.POST_PRACTITIONERS.mutationFn,
  })

export const usePatchPractitioners = () =>
  useMutation<void, FetchError, PatchPractitionersArgs>({
    mutationFn: mutations.PATCH_PRACTITIONERS.mutationFn,
  })

export const useGetTreatmentGuide = (
  treatmentGuideId: string,
  accessor: Accessor,
  options?: { onSuccess: (res: { data: ITreatmentGuide }) => void },
  enabled?: boolean,
) =>
  useQuery<ITreatmentGuide, FetchError, { data: ITreatmentGuide }, string[]>({
    queryKey: [queries.GET_TREATMENT_GUIDES.queryKey[0], treatmentGuideId as string],
    queryFn: (context) => queries.GET_TREATMENT_GUIDES.queryFn(context, treatmentGuideId, accessor),
    enabled: enabled ?? Boolean(treatmentGuideId),
    ...(options && options),
  })
export const usePostTreatmentGuides = () =>
  useMutation<PostTreatmentGuides, FetchError, PostTreatmentGuidesArgs>({
    mutationFn: mutations.POST_TREATMENT_GUIDES.mutationFn,
  })

export const usePatchTreatmentGuides = (accessor: Accessor, options?: { onSuccess: () => void }) =>
  useMutation<void, FetchError, PatchTreatmentGuideArgs>({
    mutationFn: (data) => mutations.PATCH_TREATMENT_GUIDES.mutationFn(data, accessor),
    ...(options && options),
  })

/** Returns an array of high level treatment definitions if treatmentDefinitionId is undefined */
export const useGetTreatmentDefinition = (
  treatmentDefinitionId: string | undefined,
  accessor: Accessor,
  enabled: boolean,
) =>
  useQuery<GetTreatmentDefinition, FetchError, GetTreatmentDefinition, string[]>({
    queryKey: [queries.GET_TREATMENT_DEFINITION.queryKey[0], treatmentDefinitionId || ''],
    queryFn: (context) => queries.GET_TREATMENT_DEFINITION.queryFn(context, treatmentDefinitionId || '', accessor),
    enabled: enabled,
  })

export const useGetTreatmentDefinitions = () =>
  useQuery<GetTreatmentDefinitions, FetchError, GetTreatmentDefinitions>({
    queryKey: [queries.GET_TREATMENT_DEFINITIONS],
    queryFn: queries.GET_TREATMENT_DEFINITIONS.queryFn,
  })

export const usePostS3PutObject = (options?: {
  onSuccess: (data: PostAttachments, variables: PostAttachmentsArgs) => void
}) =>
  useMutation<PostAttachments, FetchError, PostAttachmentsArgs>({
    mutationFn: mutations.POST_AUTH_S3_PUT_OBJECT.mutationFn,
    ...(options && options),
  })

export const useGetS3PutObject = (accessor: Accessor) =>
  useMutation<{ data: { url: string } }, FetchError, GetAttachmentsArgs>({
    mutationFn: (context) => mutations.GET_AUTH_S3_PUT_OBJECT.mutationFn(context, accessor),
  })

export const useGetPatientOverview = () =>
  useMutation<GetPatientOverview, FetchError, GetPatientOverviewArgs>({
    mutationFn: mutations.GET_PATIENT_OVERVIEW.mutationFn,
  })

export const useGetPracticePublic = (practiceId: string) =>
  useQuery<PracticePublic, FetchError, { data: PracticePublic }, string[]>({
    queryKey: [queries.GET_PRACTICE_PUBLIC.queryKey[0], practiceId as string],
    queryFn: queries.GET_PRACTICE_PUBLIC.queryFn,
    enabled: Boolean(practiceId),
  })

export const useGetPracticeInstalments = (
  { amount, practiceId }: GetPracticeInstalmentsArgs,
  options?: { enabled?: boolean },
) =>
  useQuery<GetPracticeInstalments, FetchError, GetPracticeInstalments, string[]>({
    queryKey: [queries.GET_PRACTICE_INSTALMENTS.queryKey[0], amount, practiceId],
    queryFn: (context) => queries.GET_PRACTICE_INSTALMENTS.queryFn(context, amount, practiceId),
    enabled: options?.enabled,
  })

export const usePostDividebuyRedirect = (options?: { onSuccess: (data: { data: { url: string } }) => void }) =>
  useMutation<{ data: { url: string } }, FetchError, PostDividebuyRedirectArgs, string[]>({
    mutationFn: (context) => mutations.POST_DIVIDEBUY_REDIRECT.mutationFn(context),
    ...(options && options),
  })

export const usePostMagicLink = (accessor: Accessor) =>
  useMutation<void, FetchError, { email: string }, string[]>({
    mutationFn: (data) => mutations.POST_MAGIC_LINK.mutationFn(data, accessor),
  })

export const usePostDividebuySoftSearch = () =>
  useMutation<{ data: { url: string } }, FetchError, { amount: number }, string[]>({
    mutationFn: (data) => mutations.POST_DIVIDEBUY_SOFT_SEARCH.mutationFn(data),
  })
