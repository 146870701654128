import { Paragraph } from '@library/components/atoms/Paragraph/Paragraph'
import clsx from 'clsx'

import { Label } from '../Label'

import { getArrowClasses } from './utils'

const modalClasses = `
  relative
  flex
  items-center
  justify-center
  max-w-[260px]
  border
  border-borderNeutralDefault
  bg-white
  rounded-lg
`

const arrowBaseClasses = `
  absolute
  bg-white
  z-tooltip
  border
  border-borderNeutralDefault
`

export type TooltipModalProps = {
  title?: string
  supportText?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
}

export const TooltipModal = ({ title, supportText, position = 'top' }: TooltipModalProps) => {
  const arrowPositionClasses = getArrowClasses(position)

  return (
    <>
      <div className={clsx(modalClasses)}>
        <div className={clsx(supportText ? 'p-3' : 'px-2 py-1.5', 'flex flex-col gap-y-1 whitespace-no-wrap w-full')}>
          {title && <Label size="small" labelStrong={false} labelText={title} />}
          {supportText && (
            <Paragraph size="caption" className="!text-foregroundNeutralSecondary">
              {supportText}
            </Paragraph>
          )}
        </div>
        <div role="tooltip-arrow" className={clsx(arrowPositionClasses, arrowBaseClasses)}></div>
      </div>
    </>
  )
}

TooltipModal.displayName = 'TooltipModal'
