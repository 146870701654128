import { InputHTMLAttributes, useRef } from 'react'
import { Label } from '@library/components/molecules'
import { Size } from '@library/types'
import { transitionClasses } from '@library/utils'
import clsx from 'clsx'

import {
  baseClasses,
  baseDotClasses,
  getDisabledDotClasses,
  getDotSizeClasses,
  getHoverClasses,
  getSizeClasses,
  getStateClasses,
} from './utils'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  value?: string
  checked?: boolean
  labelText?: string
  helperText?: string
  labelLeft?: boolean
  radioSize?: Extract<Size, 'small' | 'medium' | 'large'>
  labelStrong?: boolean
}

export const Radio = ({
  radioSize = 'medium',
  labelStrong,
  name,
  value,
  labelText,
  helperText,
  labelLeft,
  disabled,
  ...props
}: RadioProps) => {
  const ref = useRef(null)
  return (
    <label
      data-testid={`radio-label-${name}-${value}`}
      htmlFor={value}
      className={clsx(
        labelLeft ? 'gap-4 justify-between' : 'gap-3 justify-start',
        'max-w-[22.5rem] inline-flex items-start select-none w-full',
      )}
    >
      {labelText && (
        <Label
          size={radioSize}
          labelStrong={labelStrong}
          labelText={labelText}
          helperText={helperText}
          appearance="secondary"
          className={clsx(labelLeft ? 'order-first' : 'order-last')}
        />
      )}
      <input
        ref={ref}
        id={value!}
        value={value!}
        role="radio"
        type="radio"
        className="hidden"
        disabled={disabled}
        {...props}
      />
      <div className="p-[0.125rem]">
        <span
          className={clsx(
            baseClasses,
            transitionClasses,
            getSizeClasses(radioSize),
            getStateClasses(props.defaultChecked || props.checked!, disabled!),
            getHoverClasses(props.defaultChecked || props.checked),
          )}
          data-testid={`radio`}
        >
          <span
            data-testid="radio-dot"
            className={clsx(
              baseDotClasses,
              getDotSizeClasses(radioSize),
              getDisabledDotClasses(disabled),
              transitionClasses,
            )}
          ></span>
        </span>
      </div>
    </label>
  )
}

Radio.displayName = 'Radio'
